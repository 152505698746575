import logo from './logo.svg';
import './App.css';
import ProductSearch from './FindMunchie'

function App() {
  return (
    <div>
      <ProductSearch />
    </div>
  );
}

export default App;

// productsData.js

const productData = [
    {
      id: 1,
      name: "White Rice",
      searchKeyWords: ['rice', 'polished rice', 'best rice in dallas'],
      url: "https://www.google.com",
      img: "" // Add image URL if available
    },
    {
      id: 2,
      name: "Chia Seeds",
      searchKeyWords: ['Srilankan Rice', 'Matta rice', 'single polished rice', 'Chia Seeds'],
      url: "https://www.instagram.com/reel/DBktcIlOrXP/",
      img: "https://barkasbiryaniimages.s3.us-east-1.amazonaws.com/Chiaseeds.png" // Add image URL if available
    },
    {
      id: 3,
      name: "Brown Rice",
      searchKeyWords: ['fiber Rice', 'unpolished rice', 'rice'],
      url: "https://www.google.com",
      img: "https://barkasbiryaniimages.s3.us-east-1.amazonaws.com/Screenshot+2024-11-07+at+1.00.19%E2%80%AFPM+copy.png" // Add image URL if available
    },
    {
        id: 4,
        name: "Soleus Pushups",
        searchKeyWords: ['soleus muscle', 'lower blood sugar', 'exercise'],
        url: "https://www.instagram.com/reel/C_mb2cgtN_i/",
        img: "https://monitormunchiesbannerimages.s3.us-east-1.amazonaws.com/Screenshot%202024-09-06%20at%202.25.22%E2%80%AFPM%20copy.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=ASIA2VBHABOIR2IKBLOT%2F20241108%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20241108T180201Z&X-Amz-Expires=300&X-Amz-Security-Token=IQoJb3JpZ2luX2VjENr%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIFM4jLVWP8TbOufj5WsBIxKnQGbah3QviLchycA58rImAiEA8GWhJQN5%2FQlhFZkvc7Y7N4dXUQUie89sY%2Fj6RvhOJDYq6AIIYxAEGgw3MzIzNzM3MTU4NTciDLxe%2Fk6caXhLqJX4PyrFAnBG8siOc263F9W9I82U7RKzypESIhH99q6ovQ1jOc5UAl7yA3OeJcyTC7bBypCPHOlDXklDpjPuYJWjIuf453ZHMjzsL61C%2FU9wKctuGR1o9P5x6Zux2G1a6YmrLN8xpEKLqoQx%2FubwC2rKYLJbcAfSCQ0B54mHyY8gC2HArsh64nIb4mvinxasE0kS2qjZywe1l69eRU3MGCAhMCMsJ2DVVO7teqWW12O%2FTF5G8ziMcHbTBZ1zIJN%2BsA5jNnqNpWEO%2Fq8cG9YIgvRpQapgvmmmluYdnVgRNWW56Euzk6UDojN4iansoXVqVDeXXtko%2BaeBXr8OunTXGaqjXJYtgXq556H954IcknQ1KxVW0pqnGS9mgv%2B2BhsxcZnG%2FWfBihbhglFl%2FkO0kjb41onVYTA4iyYd11KQflLmS9ppcxfZnORLMhww3KC5uQY6swIk0H0U5d9D8yfxbcBs%2Bx8KyKmIind8IzGnrlu3GXxhl0AIxkewDoHnPkLaaHP%2FJiuLZ7qot3EhMctYbAplaJf6JWExKYZW7ZrxCiDaGAbFGpYIT%2FNFvLZXBEwjvYkqxHF7BdOP7UCh7KFZeYMyI5UBhqPM3gY19nnjfqxtb7GFDK2KMc3mN%2Fup6JCbO%2BlYnTiVjwUexSrgGvlAtGSArvsSw03GLWNBt5PlJMFNBIdhmzIkZVHysoHtpYI%2FEtcDPuYJxXS6BWIzmnk%2F2eb2OeytWszNCdZEK5mInHD%2BkaUQ%2F4IXhdQSGOtyrN%2BSSpKzYHFUQIrpF6C8rhQmBrB1g26Q%2BBqldDUpvXlTuskH0syZ1vrXxiyvxWoTqO8BGB1wbCjLR1umS8IDB4nfVMOCU6xq9tGR&X-Amz-Signature=cf47cbd5944077d53148b8241e5d22f863e90d6a1b2944ae683b692b399af630&X-Amz-SignedHeaders=host&response-content-disposition=inline" // Add image URL if available
      },
      {
        id: 5,
        name: "Uggani or Puffed rice",
        searchKeyWords: ['uggani', 'puffed rice', 'borugulu upma'],
        url: "https://www.instagram.com/p/C5FsPJlOa5h/",
        img: "https://monitormunchiesbannerimages.s3.us-east-1.amazonaws.com/Screenshot%202024-09-06%20at%202.25.22%E2%80%AFPM%20copy.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=ASIA2VBHABOIR2IKBLOT%2F20241108%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20241108T180201Z&X-Amz-Expires=300&X-Amz-Security-Token=IQoJb3JpZ2luX2VjENr%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIFM4jLVWP8TbOufj5WsBIxKnQGbah3QviLchycA58rImAiEA8GWhJQN5%2FQlhFZkvc7Y7N4dXUQUie89sY%2Fj6RvhOJDYq6AIIYxAEGgw3MzIzNzM3MTU4NTciDLxe%2Fk6caXhLqJX4PyrFAnBG8siOc263F9W9I82U7RKzypESIhH99q6ovQ1jOc5UAl7yA3OeJcyTC7bBypCPHOlDXklDpjPuYJWjIuf453ZHMjzsL61C%2FU9wKctuGR1o9P5x6Zux2G1a6YmrLN8xpEKLqoQx%2FubwC2rKYLJbcAfSCQ0B54mHyY8gC2HArsh64nIb4mvinxasE0kS2qjZywe1l69eRU3MGCAhMCMsJ2DVVO7teqWW12O%2FTF5G8ziMcHbTBZ1zIJN%2BsA5jNnqNpWEO%2Fq8cG9YIgvRpQapgvmmmluYdnVgRNWW56Euzk6UDojN4iansoXVqVDeXXtko%2BaeBXr8OunTXGaqjXJYtgXq556H954IcknQ1KxVW0pqnGS9mgv%2B2BhsxcZnG%2FWfBihbhglFl%2FkO0kjb41onVYTA4iyYd11KQflLmS9ppcxfZnORLMhww3KC5uQY6swIk0H0U5d9D8yfxbcBs%2Bx8KyKmIind8IzGnrlu3GXxhl0AIxkewDoHnPkLaaHP%2FJiuLZ7qot3EhMctYbAplaJf6JWExKYZW7ZrxCiDaGAbFGpYIT%2FNFvLZXBEwjvYkqxHF7BdOP7UCh7KFZeYMyI5UBhqPM3gY19nnjfqxtb7GFDK2KMc3mN%2Fup6JCbO%2BlYnTiVjwUexSrgGvlAtGSArvsSw03GLWNBt5PlJMFNBIdhmzIkZVHysoHtpYI%2FEtcDPuYJxXS6BWIzmnk%2F2eb2OeytWszNCdZEK5mInHD%2BkaUQ%2F4IXhdQSGOtyrN%2BSSpKzYHFUQIrpF6C8rhQmBrB1g26Q%2BBqldDUpvXlTuskH0syZ1vrXxiyvxWoTqO8BGB1wbCjLR1umS8IDB4nfVMOCU6xq9tGR&X-Amz-Signature=cf47cbd5944077d53148b8241e5d22f863e90d6a1b2944ae683b692b399af630&X-Amz-SignedHeaders=host&response-content-disposition=inline" // Add image URL if available
      },
      {
        id: 6,
        name: "jowar roti or jonna roti",
        searchKeyWords: ['jowar roti', 'jonna roti', 'jonnalu'],
        url: "https://www.instagram.com/p/C5HY4bmpowx/",
        img: "" // Add image URL if available
      },
      {
        id: 7,
        name: "rava idly peanut chutney or idly chutney",
        searchKeyWords: ['rava idly peanut chutney', 'idly chutney', 'idli chatnee'],
        url: "https://www.instagram.com/p/C5KbXccNbbs/",
        img: "" // Add image URL if available
      },
      {
        id: 8,
        name: "whole wheat chapati or chapati",
        searchKeyWords: ['whole wheat chapati', 'chapathi', 'chappati'],
        url: "https://www.instagram.com/p/C5ZRb5mJcso/",
        img: "" // Add image URL if available
      },
      {
        id: 9,
        name: "hyderabadi chicken dum biryani or chicken biryani",
        searchKeyWords: ['hyderabadi chicken dum biryani', 'chicken biryani', 'dum biryani'],
        url: "https://www.instagram.com/p/C5rmKpHPd_o/",
        img: "" // Add image URL if available
      },
      {
        id: 10,
        name: "fermented rice or chadannam",
        searchKeyWords: ['chadannam', 'saddi annam', 'fermented rice water'],
        url: "https://www.instagram.com/p/C51j9Gpv2Dr/",
        img: "" // Add image URL if available
      },
      {
        id: 11,
        name: "brown rice or brown rice chicken curry",
        searchKeyWords: ['brown rice', 'chicken brown rice', 'curry'],
        url: "https://www.instagram.com/p/C59j44fOmM4/",
        img: "" // Add image URL if available
      },
      {
        id: 12,
        name: "millets dosa or millet dosa",
        searchKeyWords: ['millet dosa', 'dosa', 'millets'],
        url: "https://www.instagram.com/p/C6E4y6uPj-1/",
        img: "" // Add image URL if available
      },
      {
        id: 13,
        name: "dosa or dosa chutney",
        searchKeyWords: ['dosa', 'dosa chutney', 'dosa curry'],
        url: "https://www.instagram.com/p/C5_9-e8vd2n/",
        img: "" // Add image URL if available
      },
      {
        id: 14,
        name: "pesara attu or pesarattu peanut chutney",
        searchKeyWords: ['pesarattu chutney', 'pesarattu peanut', 'peanut chutney'],
        url: "https://www.instagram.com/p/C6FuhNiOJ8z/",
        img: "" // Add image URL if available
      },
      {
        id: 15,
        name: "Pazhaya Soru or chadannam with curd or fermented rice with curd",
        searchKeyWords: ['Pazhaya Soru', 'chadannam with curd', 'fermented rice with curd'],
        url: "https://www.instagram.com/p/C6J3Fc8uZLF/",
        img: "" // Add image URL if available
      },
      {
        id: 16,
        name: "wheat upma or godhuma rava upma",
        searchKeyWords: ['upma', 'godhuma rava upma', 'wheat upma', 'goduma rava upma'],
        url: "https://www.instagram.com/p/C6MzqIpOE0w/",
        img: "" // Add image URL if available
      },
      {
        id: 17,
        name: "tandoori roti and palak paneer",
        searchKeyWords: ['tandoori roti', 'tandoori roti and palak paneer', 'tanduri'],
        url: "https://www.instagram.com/p/C6PgQAFuqYx/",
        img: "" // Add image URL if available
      },
      {
        id: 18,
        name: "red rice and chicken curry or srilanka red rice",
        searchKeyWords: ['red rice', 'red rice chicken curry', 'srilanka red rice'],
        url: "https://www.instagram.com/p/C6UIi1dux7q/",
        img: "" // Add image URL if available
      },
      {
        id: 19,
        name: "overnight oats or oats meal",
        searchKeyWords: ['overnight oats', 'oats', 'oats meal'],
        url: "https://www.instagram.com/p/C6acjS0OD5w/",
        img: "" // Add image URL if available
      },
      {
        id: 20,
        name: "ragi java or finger millets soup",
        searchKeyWords: ['ragi java', 'finger millets soup', 'raagi jaava'],
        url: "https://www.instagram.com/p/C6c-XxUPhqZ/",
        img: "" // Add image URL if available
      },
      {
        id: 21,
        name: "chai or tea",
        searchKeyWords: ['chai', 'tea', 'masala chai'],
        url: "https://www.instagram.com/p/C6fjLvhN-Wq/",
        img: "" // Add image URL if available
      },
      {
        id: 22,
        name: "sambar rice",
        searchKeyWords: ['sambar rice', 'saambar', 'saambaar rice'],
        url: "https://www.instagram.com/p/C6g5b7EODfh/",
        img: "" // Add image URL if available
      },
      {
        id: 23,
        name: "ghee chapati",
        searchKeyWords: ['ghee chapati', 'ghee chappathi', 'ghee chapathi'],
        url: "https://www.instagram.com/p/C6plyYNu_Xd/",
        img: "" // Add image URL if available
      },
      {
        id: 24,
        name: "abc juice",
        searchKeyWords: ['apple beetroot carrot juice', 'abc juice', 'juice'],
        url: "https://www.instagram.com/p/C6qjBCFuqDs/",
        img: "" // Add image URL if available
      },
      {
        id: 25,
        name: "mango or mamidi pandu",
        searchKeyWords: ['mango', 'mamidi pandu', 'maamidi'],
        url: "https://www.instagram.com/p/C6teJQQuaSi/",
        img: "" // Add image URL if available
      },
      {
        id: 26,
        name: "water melon or pucha kaya",
        searchKeyWords: ['pucha kaya', 'watermelon', 'water'],
        url: "https://www.instagram.com/p/C6xTqEUuPsM/",
        img: "" // Add image URL if available
      },
      {
        id: 27,
        name: "sweet potato or chilagadadumpa",
        searchKeyWords: ['chilagadadumpa', 'sweet potato', 'potato', 'dumpa'],
        url: "https://www.instagram.com/p/C6zpZm4Owl5/",
        img: "" // Add image URL if available
      },
      {
        id: 28,
        name: "avocado toast",
        searchKeyWords: ['avocado toast', 'toast', 'avocado'],
        url: "https://www.instagram.com/p/C64Id38O6wC/",
        img: "" // Add image URL if available
      },
      {
        id: 29,
        name: "coconut water or kobbari neellu",
        searchKeyWords: ['kobbari neellu', 'coconut water', 'water'],
        url: "https://www.instagram.com/p/C67f5Jwud_O/",
        img: "" // Add image URL if available
      },
      {
        id: 30,
        name: "sugarcane juice or cheruku rasam",
        searchKeyWords: ['sugarcane juice', 'cane juice', 'juice', 'cheruku rasam'],
        url: "https://www.instagram.com/p/C69xD5tOnNT/",
        img: "" // Add image URL if available
      },
      {
        id: 31,
        name: "panasa or jack fruit",
        searchKeyWords: ['jack fruit', 'fruit', 'pandu', 'panasa pandu', 'kaya'],
        url: "https://www.instagram.com/p/C7AWQEgOUg6/",
        img: "" // Add image URL if available
      },
      {
        id: 32,
        name: "quinoa",
        searchKeyWords: ['quinoa', 'quinoa with veg curry', 'quinova', 'kinova', 'kinoa'],
        url: "https://www.instagram.com/p/C7C5qu9vfy-/",
        img: "" // Add image URL if available
      },
      {
        id: 33,
        name: "jamakaya or guava",
        searchKeyWords: ['jamakaya', 'kaya', 'pandu', 'fruit', 'guava', 'jaama'],
        url: "https://www.instagram.com/p/C7FfhYPP0be/",
        img: "" // Add image URL if available
      },
      {
        id: 34,
        name: "koralu or foxtail millets",
        searchKeyWords: ['koralu', 'foxtail millets', 'millets', 'korralu'],
        url: "https://www.instagram.com/p/C7QyQaZOJq9/",
        img: "" // Add image URL if available
      },
      {
        id: 35,
        name: "gulab jamun",
        searchKeyWords: ['gulab jamun', 'gulaab jam', 'sweet', 'dessert'],
        url: "https://www.instagram.com/p/C7S6m2JOb8O/",
        img: "" // Add image URL if available
      },
      {
        id: 36,
        name: "thumsup",
        searchKeyWords: ['thumsup', 'thumbsup', 'soft drink', 'cool drink', 'cold drink'],
        url: "https://www.instagram.com/p/C7XiBXyP1D_/",
        img: "" // Add image URL if available
      },
      {
        id: 37,
        name: "veg mini thali",
        searchKeyWords: ['veg mini thali', 'vegetarian meals', 'veg meals', 'thali', 'mini'],
        url: "https://www.instagram.com/p/C7hljDEvPU0/",
        img: "" // Add image URL if available
      },
      {
        id: 38,
        name: "dryfruit laddu",
        searchKeyWords: ['dryfruit laddu', 'laddu', 'sweet', 'dessert','dry fruit laddu'],
        url: "https://www.instagram.com/p/C7pi0_tPqJg/",
        img: "" // Add image URL if available
      },
      {
        id: 39,
        name: "tandoori chicken",
        searchKeyWords: ['tandoor', 'tandoori chicken', 'chicken', 'grill', 'tanduri'],
        url: "https://www.instagram.com/p/C7sKikSvb11/",
        img: "" // Add image URL if available
      },
      {
        id: 40,
        name: "poha",
        searchKeyWords: ['poha', 'atukula upma', 'upma', 'atukulu', 'athukulu', 'flat rice'],
        url: "https://www.instagram.com/p/C7xDA98vgjq/",
        img: "" // Add image URL if available
      },
      {
        id: 41,
        name: "double egg omlet",
        searchKeyWords: ['egg omlet', 'double egg omlet', 'kodi guddu', 'omlette', 'omlet'],
        url: "https://www.instagram.com/p/C72CzrcPD-J/",
        img: "" // Add image URL if available
      },
      {
        id: 42,
        name: "veg mini thali",
        searchKeyWords: ['veg mini thali', 'vegetarian meals', 'veg meals', 'thali', 'mini'],
        url: "https://www.instagram.com/p/C7hljDEvPU0/",
        img: "" // Add image URL if available
      },
      {
        id: 43,
        name: "diet coke",
        searchKeyWords: ['coca cola', 'diet coke', 'coke', 'cool drink','soft drink', 'cold drink'],
        url: "https://www.instagram.com/p/C75t6AgP7in/",
        img: "" // Add image URL if available
      },
      {
        id: 44,
        name: "tandoor paneer tikka",
        searchKeyWords: ['tandoor', 'tandoori paneer tikka', 'paneer', 'grill', 'tanduri', 'paner thikka'],
        url: "https://www.instagram.com/p/C7-cl4tuS0h/",
        img: "" // Add image URL if available
      },
      {
        id: 45,
        name: "vada pav",
        searchKeyWords: ['vada pav', 'pav', 'paav', 'snack'],
        url: "https://www.instagram.com/p/C8BDt5jvNNw/",
        img: "" // Add image URL if available
      },
      {
        id: 46,
        name: "sambar vada",
        searchKeyWords: ['sambar vada', 'vada', 'saambar', 'dip', 'sambar vada dip'],
        url: "https://www.instagram.com/p/C8JEoFbuMLN/",
        img: "" // Add image URL if available
      },
      {
        id: 47,
        name: "bru coffee",
        searchKeyWords: ['bru coffee', 'coffee', 'instant coffee', 'bru', 'kaapi'],
        url: "https://www.instagram.com/p/C8MpC4RuzED/",
        img: "" // Add image URL if available
      },
      {
        id: 48,
        name: "maggi noodles",
        searchKeyWords: ['maggi', 'maggi noodles', 'noodles', '2 mins noodles', 'two minutes'],
        url: "https://www.instagram.com/p/C8XCqPVuayb/",
        img: "" // Add image URL if available
      },
      {
        id: 49,
        name: "chana or chickpeas",
        searchKeyWords: ['chana', 'chickpeas', 'snack', 'channa','guggullu', 'chick peas'],
        url: "https://www.instagram.com/p/C8aiGY-v9T6/",
        img: "" // Add image URL if available
      },
      {
        id: 50,
        name: "osmania biscuits",
        searchKeyWords: ['osmania', 'osmania biscuits', 'biscuits', 'snack', 'butter biscuits'],
        url: "https://www.instagram.com/p/C8dqka5ON4M/",
        img: "" // Add image URL if available
      },
      {
        id: 51,
        name: "marie gold biscuits",
        searchKeyWords: ['marie biscuits', 'marie gold biscuits', 'snack', 'biscuits'],
        url: "https://www.instagram.com/p/C8fwXPzOyG5/",
        img: "" // Add image URL if available
      },
      {
        id: 52,
        name: "white rice with chicken curry",
        searchKeyWords: ['white rice with chicken curry', 'white rice', 'chicken curry'],
        url: "https://www.instagram.com/p/C8jg5qbNnDb/",
        img: "" // Add image URL if available
      },
      {
        id: 53,
        name: "refigirated white rice with chicken curry",
        searchKeyWords: ['refrigirated white rice', 'chicken curry', 'white rice'],
        url: "https://www.instagram.com/p/C8n168nt9UX/",
        img: "" // Add image URL if available
      },
      {
        id: 54,
        name: "pesalu or mung bean",
        searchKeyWords: ['pesalu', 'mung bean', 'moong bean'],
        url: "https://www.instagram.com/p/C8qq25Futnt/",
        img: "" // Add image URL if available
      },
      {
        id: 55,
        name: "apple cider vinegar",
        searchKeyWords: ['apple cider vinegar', 'cider', 'vinegar'],
        url: "https://www.instagram.com/p/C8tU6FDOd4I/",
        img: "" // Add image URL if available
      },
      {
        id: 56,
        name: "buttermilk",
        searchKeyWords: ['buttermilk', 'majjiga', 'chaas'],
        url: "https://www.instagram.com/p/C80hEdpOsV0/",
        img: "" // Add image URL if available
      },
      {
        id: 57,
        name: "boiled peanuts",
        searchKeyWords: ['boiled peanuts', 'peanuts', 'snack'],
        url: "https://www.instagram.com/p/C85F_snPUDT/",
        img: "" // Add image URL if available
      },
      {
        id: 58,
        name: "banana",
        searchKeyWords: ['banana', 'fruit', 'arati pandu'],
        url: "https://www.instagram.com/p/C86PIxtOw3d/",
        img: "" // Add image URL if available
      },
      {
        id: 59,
        name: "egg dosa",
        searchKeyWords: ['egg dosa', 'dosa', 'kodi guddu attu'],
        url: "https://www.instagram.com/p/C88tz2Wusp4/",
        img: "" // Add image URL if available
      },
      {
        id: 60,
        name: "mysore bonda",
        searchKeyWords: ['mysore bonda', 'mysore bajji', 'bajji', 'bonda'],
        url: "https://www.instagram.com/p/C8_KDoqO_Ed/",
        img: "" // Add image URL if available
      },
      {
        id: 61,
        name: "mutton curry",
        searchKeyWords: ['mutton curry', 'mamsam curry', 'mutton'],
        url: "https://www.instagram.com/p/C9BTv4mu1Gd/",
        img: "" // Add image URL if available
      },
      {
        id: 62,
        name: "sweet corn",
        searchKeyWords: ['sweet corn', 'boiled sweet corn', 'mokka jonna'],
        url: "https://www.instagram.com/p/C9DOD6fOyaG/",
        img: "" // Add image URL if available
      },
      {
        id: 63,
        name: "jamun or neredu pandu",
        searchKeyWords: ['neredu pandu', 'jamun', 'fruit', 'neredu'],
        url: "https://www.instagram.com/p/C9EGLY1tsoj/",
        img: "" // Add image URL if available
      },
      {
        id: 64,
        name: "fruit bowl",
        searchKeyWords: ['fruit bowl', 'fruits mix', 'mixed fruits'],
        url: "https://www.instagram.com/p/C9GLqUNvp8P/",
        img: "" // Add image URL if available
      },
      {
        id: 65,
        name: "papaya",
        searchKeyWords: ['papaya', 'papaya fruit', 'fruit'],
        url: "https://www.instagram.com/p/C9MNksCudkr/",
        img: "" // Add image URL if available
      },
      {
        id: 66,
        name: "veg pulav",
        searchKeyWords: ['veg pulav', 'vegetarian pulav', 'veg rice'],
        url: "https://www.instagram.com/p/C9Nlx7wvX7E/",
        img: "" // Add image URL if available
      },
      {
        id: 67,
        name: "coconut oil white rice part 1",
        searchKeyWords: ['cocnut oil white rice', 'white rice', 'oil', 'rice', 'part 1'],
        url: "https://www.instagram.com/p/C9Qdun0PLdY/",
        img: "" // Add image URL if available
      },
      {
        id: 68,
        name: "coconut oil white rice part 2",
        searchKeyWords: ['cocnut oil white rice', 'white rice', 'oil', 'rice', 'part 2'],
        url: "https://www.instagram.com/p/C9QfvJQvA4E/",
        img: "" // Add image URL if available
      },
      {
        id: 69,
        name: "dhampudu biyyam or handpound rice",
        searchKeyWords: ['dhampudu biyyam', 'handpound rice', 'rice', 'white rice', 'red rice'],
        url: "https://www.instagram.com/p/C9TnP0ct3aE/",
        img: "" // Add image URL if available
      },
      {
        id: 70,
        name: "sooji rava upma",
        searchKeyWords: ['sooji rava upma', 'rava upma', 'suji upma','upma'],
        url: "https://www.instagram.com/p/C9WcseVuyxj/",
        img: "" // Add image URL if available
      },
      {
        id: 71,
        name: "paneer and cashew dum biryani",
        searchKeyWords: ['paneer and cashew dum biryani', 'dum biryani', 'biryani', 'cashew', 'rice'],
        url: "https://www.instagram.com/p/C9eIWAXup_m/",
        img: "" // Add image URL if available
      },
      {
        id: 72,
        name: "whey protein shake",
        searchKeyWords: ['whey protein shake', 'protein shake', 'whey'],
        url: "https://www.instagram.com/p/C9ghf4ytI4u/",
        img: "" // Add image URL if available
      },
      {
        id: 73,
        name: "boiled eggs",
        searchKeyWords: ['boiled eggs', 'eggs', '2 boiled eggs'],
        url: "https://www.instagram.com/p/C9inKICuA9f/",
        img: "" // Add image URL if available
      },
      {
        id: 74,
        name: "illness or stress",
        searchKeyWords: ['illness', 'stress', 'fever'],
        url: "https://www.instagram.com/p/C9x_1Zjut_o/",
        img: "" // Add image URL if available
      },
      {
        id: 75,
        name: "medjool dates",
        searchKeyWords: ['medjool dates', 'dates', 'medjul'],
        url: "https://www.instagram.com/p/C90knXuvYj_/",
        img: "" // Add image URL if available
      },
      {
        id: 76,
        name: "kharbuja or muskmelon",
        searchKeyWords: ['kharbuja', 'kharbhuja', 'karbuja', 'muskmelon', 'fruit'],
        url: "https://www.instagram.com/p/C93GP7JPXIj/",
        img: "" // Add image URL if available
      },
      {
        id: 77,
        name: "ice cream with no added sugar",
        searchKeyWords: ['no sugar ice cream', 'sugarless ice cream', 'ice cream'],
        url: "https://www.instagram.com/p/C95Hp4Au3TH/",
        img: "" // Add image URL if available
      },
      {
        id: 78,
        name: "cashews or kaju",
        searchKeyWords: ['cashews', 'kaju', 'jeedi pappu','dry fruits'],
        url: "https://www.instagram.com/p/C98qbKnNQsO/",
        img: "" // Add image URL if available
      },
      {
        id: 79,
        name: "almods or badam",
        searchKeyWords: ['almonds', 'badam pappu', 'baadam', 'dry fruits'],
        url: "https://www.instagram.com/p/C-B--g-tFHm/",
        img: "" // Add image URL if available
      },
      {
        id: 80,
        name: "honey lemon water",
        searchKeyWords: ['honey lemon water', 'lemon water', 'nimma rasam'],
        url: "https://www.instagram.com/p/C-DzPnavbzd/",
        img: "" // Add image URL if available
      },
      {
        id: 81,
        name: "pista or pistachio",
        searchKeyWords: ['pista', 'pistachio', 'dry fruit'],
        url: "https://www.instagram.com/p/C-G12lpOoxQ/",
        img: "" // Add image URL if available
      },
      {
        id: 82,
        name: "sunflower seeds",
        searchKeyWords: ['sunflower seeds', 'seeds', 'dry fruits'],
        url: "https://www.instagram.com/p/C-I_vDBvRbe/",
        img: "" // Add image URL if available
      },
      {
        id: 83,
        name: "monk fruit",
        searchKeyWords: ['monk fruit', 'sugar alternative'],
        url: "https://www.instagram.com/p/C-LnKRgOKQ_/",
        img: "" // Add image URL if available
      },
      {
        id: 84,
        name: "raagi muddha",
        searchKeyWords: ['finger millet', 'ragi mudda', 'raagi muddha'],
        url: "https://www.instagram.com/p/C-URd_qu8fQ/",
        img: "" // Add image URL if available
      },
      {
        id: 85,
        name: "roasted peanuts",
        searchKeyWords: ['roasted peanuts', 'roasted ground nuts', 'roasted palli'],
        url: "https://www.instagram.com/p/C-WuUsIOJuR/",
        img: "" // Add image URL if available
      },
      {
        id: 86,
        name: "peanut chikki",
        searchKeyWords: ['peanut chikki', 'peanut', 'snack'],
        url: "https://www.instagram.com/p/C-ZYFGIOslW/",
        img: "" // Add image URL if available
      },
      {
        id: 87,
        name: "ven pongal",
        searchKeyWords: ['ven pongal', 'pongal', 'rice pongal'],
        url: "https://www.instagram.com/p/C-elGyFOaKq/",
        img: "" // Add image URL if available
      },
      {
        id: 88,
        name: "jaggery tea or bellam tea",
        searchKeyWords: ['jaggery tea', 'bellam tea', 'chai'],
        url: "https://www.instagram.com/p/C-lP9J7vA_e/",
        img: "" // Add image URL if available
      },
      {
        id: 89,
        name: "samosa",
        searchKeyWords: ['samosa', 'snack', 'samosas'],
        url: "https://www.instagram.com/p/C-n67QRPWrj/",
        img: "" // Add image URL if available
      },
      {
        id: 90,
        name: "tamarind pulihora or chintapandu pulihora",
        searchKeyWords: ['tamarind pulihora', 'pulihora', 'chintapandu pulihora','rice'],
        url: "https://www.instagram.com/p/C-ss6cKuAeW/",
        img: "" // Add image URL if available
      },
      {
        id: 91,
        name: "curd rice or daddojanam",
        searchKeyWords: ['curd rice', 'daddojanam', 'rice'],
        url: "https://www.instagram.com/p/C-ugZcjNt6N/",
        img: "" // Add image URL if available
      },
      {
        id: 92,
        name: "popcorn",
        searchKeyWords: ['popcorn', 'pop corn', 'snack'],
        url: "https://www.instagram.com/p/C-wVJ9KtcJV/",
        img: "" // Add image URL if available
      },
      {
        id: 93,
        name: "egg puff",
        searchKeyWords: ['egg puff', 'snack', 'puff'],
        url: "https://www.instagram.com/p/C-xvBLXusZD/",
        img: "" // Add image URL if available
      },
      {
        id: 94,
        name: "toor dal or mudda pappu",
        searchKeyWords: ['toor dal', 'tur daal', 'mudda pappu','pappu'],
        url: "https://www.instagram.com/p/C-6rt8fuz5-/",
        img: "" // Add image URL if available
      },
      {
        id: 95,
        name: "stevia",
        searchKeyWords: ['stevia', 'sugar alternative'],
        url: "https://www.instagram.com/p/C_APHtBO0j1/",
        img: "" // Add image URL if available
      },
      {
        id: 96,
        name: "pani puri",
        searchKeyWords: ['pani puri', 'paani poori', 'snack'],
        url: "https://www.instagram.com/p/C_CtHEXOi0b/",
        img: "" // Add image URL if available
      },
      {
        id: 97,
        name: "coconut meat or kobbari",
        searchKeyWords: ['coconut meat', 'kobbari', 'snack'],
        url: "https://www.instagram.com/p/C_KJGN2Or_z/",
        img: "" // Add image URL if available
      },
      {
        id: 98,
        name: "allulose",
        searchKeyWords: ['allulose', 'sugar alternative'],
        url: "https://www.instagram.com/p/C_Ol9YNPNN7/",
        img: "" // Add image URL if available
      },
      {
        id: 99,
        name: "nellore fish pulusu",
        searchKeyWords: ['nellore fish pulusu', 'nellore chepala pulusu', 'fish curry'],
        url: "https://www.instagram.com/p/C_UbkWQOas6/",
        img: "" // Add image URL if available
      },
      {
        id: 100,
        name: "rasam rice",
        searchKeyWords: ['rasam rice', 'rice'],
        url: "https://www.instagram.com/p/C_XM2cCO7nu/",
        img: "" // Add image URL if available
      },
      {
        id: 101,
        name: "motichoor laddu",
        searchKeyWords: ['motichoor laddu', 'laddoo', 'sweet', 'dessert'],
        url: "https://www.instagram.com/p/C_Y-m4UPzdh/",
        img: "" // Add image URL if available
      },
      {
        id: 102,
        name: "cricket",
        searchKeyWords: ['cricket', 'sports'],
        url: "https://www.instagram.com/p/C_r-D4IukSN/",
        img: "" // Add image URL if available
      },
      {
        id: 103,
        name: "walking",
        searchKeyWords: ['walking', 'sports'],
        url: "https://www.instagram.com/p/C_w6u-RO4t5/",
        img: "" // Add image URL if available
      },
      {
        id: 104,
        name: "racket sport",
        searchKeyWords: ['racket sport', 'sports'],
        url: "https://www.instagram.com/p/C_2Yn3kOxe4/",
        img: "" // Add image URL if available
      },
      {
        id: 105,
        name: "dragon fruit",
        searchKeyWords: ['dragon fruit', 'fruit'],
        url: "https://www.instagram.com/p/C_488NfOjgK/",
        img: "" // Add image URL if available
      },
      {
        id: 106,
        name: "orange juice",
        searchKeyWords: ['orange juice', 'fresh juice'],
        url: "https://www.instagram.com/p/C_68yelOjKz/",
        img: "" // Add image URL if available
      },
      {
        id: 107,
        name: "alasandhalu or bobbarlu or blackeyed peas vada",
        searchKeyWords: ['alasandhalu', 'bobbarlu', 'blackeyed peas vada'],
        url: "https://www.instagram.com/p/C_9hoC0u7_F/",
        img: "" // Add image URL if available
      },
      {
        id: 108,
        name: "dominos pizza",
        searchKeyWords: ['dominos pizza', 'pizza'],
        url: "https://www.instagram.com/p/DAC3LqAuwIu/",
        img: "" // Add image URL if available
      },
      {
        id: 109,
        name: "subway sandwich 6 foot",
        searchKeyWords: ['subway sandwich 6 foot', 'sandwich', 'sub'],
        url: "https://www.instagram.com/p/DAFqLJEurAA/",
        img: "" // Add image URL if available
      },
      {
        id: 110,
        name: "maggi atta version",
        searchKeyWords: ['maggi atta version', 'noodles', 'atta'],
        url: "https://www.instagram.com/p/DAIGRJyuNEj/",
        img: "" // Add image URL if available
      },
      {
        id: 111,
        name: "kfc chicken",
        searchKeyWords: ['kfc chicken', 'deep fried chicken'],
        url: "https://www.instagram.com/p/DAKaWgNtFB-/",
        img: "" // Add image URL if available
      },
      {
        id: 112,
        name: "starbucks cafe latte",
        searchKeyWords: ['starbucks cafe latte', 'coffee'],
        url: "https://www.instagram.com/p/DAVGJjBOsrJ/",
        img: "" // Add image URL if available
      },
      {
        id: 113,
        name: "chipotle bowl",
        searchKeyWords: ['chipotle bowl', 'mexican'],
        url: "https://www.instagram.com/p/DAYyJnyucC0/",
        img: "" // Add image URL if available
      },
      {
        id: 114,
        name: "french fries",
        searchKeyWords: ['french fries', 'snack'],
        url: "https://www.instagram.com/p/DAgw23QvSVZ/",
        img: "" // Add image URL if available
      },
      {
        id: 115,
        name: "roasted makhana or fox nuts",
        searchKeyWords: ['roasted makhana', 'fox nuts', 'snack'],
        url: "https://www.instagram.com/p/DAnDeecu6Rm/",
        img: "" // Add image URL if available
      },
      {
        id: 116,
        name: "100% whole wheat bread omlet",
        searchKeyWords: ['100% whole wheat bread omlet', 'bread omlet', 'benchmark meal'],
        url: "https://www.instagram.com/p/DAphyLZOq7Q/",
        img: "" // Add image URL if available
      },
      {
        id: 117,
        name: "water before meal",
        searchKeyWords: ['water before meal','drink'],
        url: "https://www.instagram.com/p/DAsCtBBueX7/",
        img: "" // Add image URL if available
      },
      {
        id: 118,
        name: "menthulu or fengreek seeds before meal",
        searchKeyWords: ['menthulu', 'fenugreek seeds before meal', 'drink'],
        url: "https://www.instagram.com/p/DAztWKeOY-r/",
        img: "" // Add image URL if available
      },
      {
        id: 119,
        name: "banana chips",
        searchKeyWords: ['banana chips', 'chips', 'snack'],
        url: "https://www.instagram.com/p/DA4_E4iuGw5/",
        img: "" // Add image URL if available
      },
      {
        id: 120,
        name: "lemon rice or pulihora",
        searchKeyWords: ['lemon rice', 'lemon pulihora', 'rice'],
        url: "https://www.instagram.com/p/DBAhzuXuhT6/",
        img: "" // Add image URL if available
      },
      {
        id: 121,
        name: "sourdough bread",
        searchKeyWords: ['sourdough', 'bread', 'sour dough'],
        url: "https://www.instagram.com/p/DBIcONXucLd/",
        img: "" // Add image URL if available
      },
      {
        id: 122,
        name: "diabetic rice or low gi rice part 1",
        searchKeyWords: ['diabetic rice', 'low gi rice', 'rice'],
        url: "https://www.instagram.com/p/DBNcY72uLOI/",
        img: "" // Add image URL if available
      },
      {
        id: 123,
        name: "diabetic rice or low gi rice part 2",
        searchKeyWords: ['diabetic rice', 'low gi rice', 'rice'],
        url: "https://www.instagram.com/p/DBR2ZsovoCb/",
        img: "" // Add image URL if available
      },
      {
        id: 124,
        name: "15 minutes walk",
        searchKeyWords: ['15 minutes walk', 'walk'],
        url: "https://www.instagram.com/p/DBiDbVsOh9g/",
        img: "" // Add image URL if available
      },
      {
        id: 125,
        name: "chia seeds",
        searchKeyWords: ['chia seeds', 'seeds'],
        url: "https://www.instagram.com/p/DBktcIlOrXP/",
        img: "" // Add image URL if available
      },
      {
        id: 126,
        name: "idly sambar",
        searchKeyWords: ['idli sambar', 'idly sambar'],
        url: "https://www.instagram.com/p/DBu80f9ODrU/",
        img: "" // Add image URL if available
      },
      {
        id: 127,
        name: "avakaya white rice",
        searchKeyWords: ['avakai', 'avakaya', 'rice', 'avakaya rice'],
        url: "https://www.instagram.com/p/DB0IDOqNxPn/",
        img: "https://barkasbiryaniimages.s3.us-east-1.amazonaws.com/AvakayaRice.png" // Add image URL if available
      },
      {
        id: 128,
        name: "senagala podi or spicy lentil powder",
        searchKeyWords: ['senagala podi', 'spicy lentil powder'],
        url: "https://www.instagram.com/p/DB-qiK6OhR3/",
        img: "https://barkasbiryaniimages.s3.us-east-1.amazonaws.com/senegalaPodiAlone.png" // Add image URL if available
      },
      {
        id: 129,
        name: "aavu neyyi or cow ghee",
        searchKeyWords: ['aavu neyyi', 'cow ghee', 'ghee'],
        url: "https://www.instagram.com/p/DCGNaWqtNHZ/",
        img: "https://barkasbiryaniimages.s3.us-east-1.amazonaws.com/ghee%2Bsenagalapodi.png" // Add image URL if available
      },
      {
        id: 130,
        name: "Dhaanimma pandu | pomegranate",
        searchKeyWords: ['pomegranate', 'Dhaanimma', 'fruits'],
        url: "https://www.instagram.com/p/DCQa9SNOLAD/",
        img: "https://barkasbiryaniimages.s3.us-east-1.amazonaws.com/pomegranateBanner.png" // Add image URL if available
      },
      {
        id: 131,
        name: "Thaati Bellam | palm jaggery with Tea",
        searchKeyWords: ['palm jaggery', 'thaati bellam', 'tea'],
        url: "https://www.instagram.com/p/DCVf9l5tXmj/",
        img: "https://barkasbiryaniimages.s3.us-east-1.amazonaws.com/ThatibellamTea.png" // Add image URL if available
      }
      ,
      {
        id: 132,
        name: "Tea Without Sugar vs Natural Sweeteners: The ultimate Winner Test",
        searchKeyWords: ['Tea without sugar', 'plain tea', 'tea with no sugar'],
        url: "https://www.instagram.com/reel/DCar3B5N1ma/",
        img: "https://barkasbiryaniimages.s3.us-east-1.amazonaws.com/TeaWithoutSugar.png" // Add image URL if available
      }
  ];
  
  export default productData;